import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
// import Cookies from 'js-cookie'

import VueI18n from 'vue-i18n' // 引入国际化插件包

import api from "./api";
import axios from "axios";
//导入路由模块
import router from '@/router/index.js'
import messages from './i18n/index'

Vue.use(ElementUI);
Vue.use(VueI18n) // 全局注册国际化包
Vue.prototype.$api = api;
Vue.prototype.$axios = axios;
Vue.config.productionTip = false


function getLanguage(){
  const chooseLanguage = localStorage.getItem("lang")
  if (chooseLanguage){
    return chooseLanguage
  }else{
    console.log('zh-Hant')
    return 'zh-Hant'
  }
}
const i18n = new VueI18n({
  locale: getLanguage() || 'zh-Hant',
  messages
})

const getLang = (lang)=>{
  console.log(lang,localStorage.getItem("lang"))
  localStorage.setItem('lang', lang)
  i18n.locale = lang; 
}
Vue.prototype.$getLang = getLang

new Vue({
  render: h => h(App),
  router,
  i18n
}).$mount('#app')
